<template>
  <v-row class="mt-2">
    <v-col cols="12">
      <v-card outlined :loading="$store.state.loading">
        <v-card-text class="text--primary">
          <!-- heading -->
          <v-row class="mb-6 pt-1">
            <v-col cols="12">
              <h1 class="display-1 font-weight-medium">
                Add Inventory Property
              </h1></v-col
            >
          </v-row>
          <!-- form -->
          <v-form ref="form">
            <v-row>
              <!-- destination -->
              <v-col cols="6">
                <v-text-field
                  :loading="$store.state.loading"
                  :disabled="$store.state.loading"
                  label="Destination *"
                  v-model.trim="formData.destination"
                  :rules="[textRules.required]"
                  hint="ex: Lake Tahoe"
                ></v-text-field>
              </v-col>
              <!-- property ID -->
              <v-col cols="6">
                <v-text-field
                  :loading="$store.state.loading"
                  :disabled="$store.state.loading"
                  label="Property ID *"
                  v-model.trim="formData.id"
                  :rules="[textRules.required]"
                  hint="ex: 0193 or CHAH"
                ></v-text-field>
              </v-col>
              <!-- resort name -->
              <v-col cols="6">
                <v-text-field
                  :loading="$store.state.loading"
                  :disabled="$store.state.loading"
                  label="Resort Name *"
                  v-model.trim="formData.resortName"
                  :rules="[textRules.required]"
                  hint="ex: The Capitol Hotel Downtown"
                ></v-text-field>
              </v-col>
              <!-- source -->
              <v-col cols="6">
                <v-select
                  :loading="$store.state.loading"
                  :disabled="$store.state.loading"
                  :items="[
                    { text: 'hotels.com', value: 'hc' },
                    { text: 'apple', value: 'apple' },
                    { text: 'castlemartyr', value: 'cmr' },
                    { text: 'tripbeat', value: 'tripbeat' },
                    { text: 'dreamweeks', value: 'dreamweeks' },
                  ]"
                  v-model="formData.source"
                  required
                  label="Source *"
                  :rules="[textRules.required]"
                ></v-select>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import formRules from "@/mixins/formRules";
export default {
  mixins: [formRules],
  data: () => ({
    formData: {
      dateSubmitted: new Date().toISOString().substring(0, 1),
      destination: "",
      id: "",
      items: [],
      resortName: "",
      source: "",
      template: "inventoryListings",
    },
  }),
};
</script>
